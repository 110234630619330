import { activityParamType } from '../pages/usersPage/query';
import { get } from './apiService';

export const getAllActivities = async (
  id: string,
  params: activityParamType,
  date: string
) => {
  return await get(`/activity?date=${date}&facilityIds=${id}`, params);
};
export const getAllAmenities = async (id: string) => {
  return await get(
    '/facility-amenities?size=100&sort=position,asc&facilityIds=' + id
  );
};
export const getAllDiningRestaurent = async (id: number) => {
  return await get('/dining-restaurant?facilityIds=' + id);
};
export const getActivityById = async (id: string) => {
  return await get(`/activity/${id}`);
};
export const getUsersList = async (id: string) => {
  return await get(`/activity/${id}/users`);
};
